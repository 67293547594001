import React from 'react'
import PropTypes from 'prop-types'

function DonateCard({model}) {

    const image = model.image ? require('../images/' + model.image) : null

    return (
        <div className="card">
            <div className="card-content">
                <div className="content">
                    <div className="p-2 is-uppercase spaced-text has-text-centered">
                        <p className="is-7">{model.title}</p>
                    </div>
                    <p className="title is-4 is-family-secondary has-text-centered">{model.subtitle}</p>
                    {
                        model.info.map(function (information) {
                            return (
                                <div key={information.title}>

                                    <p className="pl-4 pr-4"><strong>{information.title}: </strong> {information.value}</p>
                                </div>
                            )
                        })
                    }
                </div>
                {
                        image && <div className="card-image fifthy-space-image">
                                    <figure className="image is-square">
                                        <img src={image} alt="" />
                                    </figure>
                                </div>
                    }
            </div>
        </div>
    )
}

DonateCard.propTypes = {
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        resume: PropTypes.string,
        info: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })),
        image: PropTypes.string
    })
}

export default DonateCard;
import React from 'react'
import PropTypes from 'prop-types'
import DonateCard from './donate-card'
import SectionTitle from './section-title'

function Donate({model, id}) {

    return (
        <section className="section" id={id}>
            <div className="container">
                <SectionTitle title={model.title} subtitle={model.subtitle} resume={model.resume}/>
                
                <div className="columns mt-6">
                    {model.methods.map(function (method) {
                        return (
                            <div className="column" key={method.id}>
                                <DonateCard model={method} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

Donate.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        resume: PropTypes.string.isRequired,
        methods: PropTypes.arrayOf(PropTypes.object).isRequired
    })
}

export default Donate;
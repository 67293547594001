import React from 'react'
import PropTypes from 'prop-types'
import PresentationCard from './presentation-card'
import SectionTitle from './section-title'

function Presentation({model, id}) {

    return (
        <section className="section" id={id}>
            <div className="container">
                <SectionTitle title={model.title} subtitle={model.subtitle} resume={model.resume}/>
                
                <div className="columns mt-6">
                    {model.fiances.map(function (fiance) {
                        return (
                            <div className="column" key={fiance.id}>
                                <PresentationCard model={fiance}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

Presentation.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        resume: PropTypes.string.isRequired,
        fiances: PropTypes.arrayOf(PropTypes.object).isRequired
    })
}

export default Presentation;
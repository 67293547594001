import React from 'react'
import PropTypes from 'prop-types'

function SectionTitle(props) {

    return (
        <div className="p-6">
            <h3 className="subtitle is-size-6 is-uppercase has-text-centered spaced-text">{props.subtitle}</h3>
            <h1 className="title is-size-3 is-family-secondary has-text-centered">{props.title}</h1>
            <p className="has-text-centered ml-3 mr-3">
                    {props.resume}
            </p>
        </div>
    )
}

SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    resume: PropTypes.string
}

export default SectionTitle;
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SectionTitle from './section-title'

function Countdown ({model, id}) {

    const [timeToShow, setTimeToShow] = useState({
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    });

    useEffect(() => {
        const interval = setInterval(() => {
            let timeLeft = calculateTimeLeft(model.timeTillDate)
            setTimeToShow({ 
                days: timeLeft.days, 
                hours: timeLeft.hours, 
                minutes: timeLeft.minutes, 
                seconds: timeLeft.seconds });
        }, 1000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    })

    return (
        <section className="section" id={id}>
            <div className="container">
                <SectionTitle title={model.title} subtitle={model.subtitle}/>
                <div className="columns has-text-centered">
                    <CoundownBox number={timeToShow.days} title="días"/>
                    <CoundownBox number={timeToShow.hours} title="horas" />
                    <CoundownBox number={timeToShow.minutes} title="minutos" />
                    <CoundownBox number={timeToShow.seconds} title="segundos" />
                </div>

            </div>
        </section>
    )
}

const calculateTimeLeft = (timeTillDate) => {
    let difference = new Date(timeTillDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
        } 
    } else {
        timeLeft = {
            days: 0,
            hours:0,
            minutes: 0,
            seconds: 0
        }
    }
    return timeLeft;
}

function CoundownBox ({number, title}) {
    return (
        <div className="column">
            <div className="box p-6 is-mobile has-text-centered">
                <p className="title is-size-1 is-family-secondary">{number ?? 0}</p>
                <p className="is-uppercase spaced-text has-text-weight-light">{title}</p>
            </div>
        </div>
    )
}

Countdown.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        timeTillDate: PropTypes.string.isRequired,
    })
}

export default Countdown;
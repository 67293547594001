import React from 'react'
import PropTypes from 'prop-types'

function Hero({model, id}) {
    const image = require('../images/' + model.image)
    return (
        <section className="hero is-fullheight is-primary has-background" id={id}>
            <img className="hero-background zoomed-hover" src={image} alt="Karen y Jonatán" />
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="block">
                        <p className="title is-size-1 is-family-secondary">
                            {model.title}
                        </p>
                    </div>
                    <div className="block">
                        <p className="subtitle is-uppercase">
                            {model.subtitle}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

Hero.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired
    })
}

export default Hero;
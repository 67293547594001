import React from 'react'
import MainLayout from '../layouts/mainlayout'
import Hero from '../components/hero'
import Presentation from '../components/presentation'
import Countdown from '../components/countdown'
import { graphql } from 'gatsby'
import Gallery from '../components/gallery';
import RSVP from '../components/rsvp'
import Donate from '../components/donate'
import Helmet from 'react-helmet'

function Index({data}) {

    return (
        
        <MainLayout>
            <Helmet>
                <title>Boda Karen & Jonatán</title>
            </Helmet>
            <Hero model={data.site.siteMetadata.header} id="inicio"/>
            <Presentation model={data.site.siteMetadata.presentation} id="historia"/>
            <Countdown model={data.site.siteMetadata.countdown} id="countdown"/>
            <Presentation model={data.site.siteMetadata.place} id="place"/>
            <Gallery model={data.site.siteMetadata.gallery} id="galeria" />
            <Hero model={data.site.siteMetadata.verse} id="verse"/>
            <Donate model={data.site.siteMetadata.donate} id="donate"/>
            {/* <RSVP model={data.site.siteMetadata.rsvp} id="rsvp" />*/}
        </MainLayout>
    )
}

export const query = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                header {
                    title
                    subtitle
                    image
                }
                presentation {
                    title
                    subtitle
                    resume
                    fiances {
                        id
                        image
                        name
                        resume
                        title
                      }
                }
                countdown {
                    title
                    subtitle
                    timeTillDate
                }
                gallery {
                    title
                    subtitle
                    images
                }
                rsvp {
                    title
                    subtitle
                    resume
                    buttonLabel
                }
                verse {
                    title,
                    subtitle,
                    image,
                }
                donate {
                    title
                    subtitle
                    resume
                    methods {
                        id,
                        title,
                        subtitle,
                        info {
                            title
                            value
                        }
                        image
                    }
                }
                place {
                    title
                    subtitle
                    resume
                    fiances {
                        id
                        name
                        resume
                        title
                      }
                }
            }
        }
    }
`
export default Index;
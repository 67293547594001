import React, { useState } from 'react';
import PropTypes from 'prop-types'
import SectionTitle from './section-title'
import { Lightbox } from "react-modal-image";

function Gallery({model, id}) {

    const [selectedImage, setSelectedImage] = useState(null)

    const closeLightbox = () => {
        setSelectedImage(null)
      };

    return (
        <section className="section" id={id}>
            <SectionTitle title={model.title} subtitle={model.subtitle}/>
            <div className="columns is-multiline">
            {model.images.map(function (image, index) {
                const imageObject = require('../images/' + image)
                    return (
                        <div className="column is-one-third zoomed-hover" key={index}>
                            <img src={imageObject} onClick={() => {
                                setSelectedImage(imageObject)
                            }}/>
                        </div>
                    )
                })
            }
            {
                selectedImage && (
                    <Lightbox
                        medium={selectedImage}
                        onClose={closeLightbox}
                        hideDownload={true}
                        />
                    )
                }
            </div>
        </section>
    )
}

Gallery.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired
    })
}

export default Gallery;
import React from 'react'
import PropTypes from 'prop-types'

function PresentationCard({model}) {
    const image = model.image ? require('../images/' + model.image) : null
    return (
        <div className="card">
            {
                image &&
            
                <div className="card-image">
                    <figure className="image">
                        <img className="zoomed-hover" src={image} alt={model.name} />
                    </figure>
                </div>
            }
            <div className="card-content">
                <div className="content">
                    <div className="p-2 is-uppercase spaced-text has-text-centered">
                        <p className="is-7">{model.title}</p>
                    </div>
                    <p className="title is-4 is-family-secondary has-text-centered">{model.name}</p>
                    <p className="pl-4 pr-4 pb-4 has-text-centered">{model.resume}</p>
                </div>
            </div>
        </div>
    )
}

PresentationCard.propTypes = {
    model: PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        resume: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    })
}

export default PresentationCard;